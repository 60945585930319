<template>
    <b-modal id="modal-list-lesson" size="lg" hide-footer>
    <div class="spaceModal">
      <div class="header">
        <p class="nomeCurso">{{ courseAllProgress[0].course.title }}</p>
        <p class="nomeTurma">{{ courseAllProgress[0].title }}</p>
      </div>
      <div class="line"></div>
      <div class="spaceTable">
          <div class="gridHeader">
            <p>Nome do Módulo</p>
            <p>Nome da Aula</p>
            <p>Nota da Aula</p>
            <p>Status da Aula</p>
          </div>
          <div class="gridBody" v-for="item in courseAllProgress[1]" :key="item.id">
            <p class="title">{{ item.module.title }}</p>
            <p class="title">{{ item.title }}</p>
            <star-rating
                :show-rating="false"
                :star-size="20"
                :rating="item.rating || 0"
            >
            </star-rating>
            <p class="status complete" v-if="item.is_completed">Completa</p>
            <p class="status incomplete" v-else>Incompleta</p>
          </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import StarRating from "vue-star-rating";

export default {  
    components:{
        StarRating
    },
    props:['courseAllProgress']
}
</script>

<style lang="scss">
#modal-list-lesson{
  header{
    border: none !important;
  }
  label{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: var(--fontcolor);
  }
  .spaceModal{
    .spaceTable::-webkit-scrollbar {
        background-color: #fff;
        width: 2px;
    }

    .spaceTable::-webkit-scrollbar-track {
        background-color: #c7c7c7;
    }

    .spaceTable::-webkit-scrollbar-thumb {
        background-color: #4d4d4d52;
        border-radius: 31px;
    }
    .spaceTable::-webkit-scrollbar-button {
        display:none;
    }
    .spaceTable{
        margin-top: 32px;
        width: 100%;
        height: 56vh;
        overflow: auto;
        .gridBody{
            display: grid;
            grid-template-columns: 3fr 3fr 2fr 2fr;
            margin-bottom: 10px;
            padding-top: 15px;
            .vue-star-rating{
                margin-top: -5px;
                pointer-events: none;
            }
            .title{
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                letter-spacing: 0.2px;
                color: #81858E;
                margin-bottom: 0px;
            }
            .status{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                letter-spacing: 0.2px;
                margin-bottom: 0px;
            }
            .incomplete{
                color: #FF0C37;
            }
            .complete{
                color: #00E4A0;
            }
        }
        .gridHeader{
            display: grid;
            grid-template-columns: 3fr 3fr 2fr 2fr;
            p{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.2px;
                margin-bottom: 5px;
                color: var(--fontcolor);
            }
        }
    }
    .line{
        background: #E5E5E5;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        width: 100%;
    }
    .header{
        margin-top: -10px;
      .nomeCurso{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        letter-spacing: 0.2px;
        color: var(--fontcolor);
        margin-bottom: 5px;
      }
      .nomeTurma{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #81858E;
        margin-bottom: 32px;
      }
    }
  }
}

</style>